import React, { useEffect } from "react"
import {
    Carousel,
    initTWE,
} from "tw-elements";

import { Start } from "./Start";
import { Footer } from "./Footer"
import { GetStarted } from "./GetStarted"
import { LandingHeader } from "./LandingHeader"
import teamSpirit from "../../assets/images/yXPdzhbXA7gHV6NDe.png"

import "../../assets/css/aurora.css"
import { Helmet } from "react-helmet";

export const Landing = () => {
    let i = 0;
    let j = 0;
    let currentWord = "";
    let isDeleting = false;

    const words = [
        "Elevate Acts of Appreciation",
        "Live the Art and Feel the Love",
        "Turn Applause into Action"
    ];

    function typewriterEffect() {
        currentWord = words[i];
        if (isDeleting) {
            document.getElementById("typewriter").textContent = currentWord.substring(0, j - 1);
            j--;
            if (j == 0) {
                isDeleting = false;
                i++;
                if (i == words.length) {
                    i = 0;
                }
            }
        } else {
            document.getElementById("typewriter").textContent = currentWord.substring(0, j + 1);
            j++;
            if (j == currentWord.length) {
                isDeleting = true;
            }
        }
        setTimeout(typewriterEffect, 130);
    }

    useEffect(() => {
        function handlePageLoad() {
            typewriterEffect()

            initTWE({ Carousel });
        }

        window.addEventListener('load', handlePageLoad);

        return () => {
            window.removeEventListener('load', handlePageLoad);
        };
    }, [])

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    Big Fan
                </title>
            </Helmet>

            <div className="flex h-screen w-full">

                <LandingHeader />

                <div className="flex flex-col w-full relative isolate">

                    <Start />

                    <div className="w-full flex-grow">
                        <div className="w-full px-3 py-5 bg-orange-100">
                            <div className="mx-auto container md:text-xl text-base md:max-w-4xl lg:max-w-4xl xl:max-w-5xl pb-5">
                                <div className="sm:flex flex items-center">
                                    <div className="sm:w-1/2 p-10 hidden md:block">
                                        <div className="image object-center text-center">
                                            <img className="h-full scale-125" src={teamSpirit} alt={"team_spirit"} loading="lazy" />
                                        </div>
                                    </div>

                                    <div className="sm:w-1/2 px-4">
                                        <div className="text-base">
                                            <h1 className="mt-2 text-2xl font-bold tracking-tight text-orange-600 sm:text-3xl">
                                                Turn Applause into Action
                                            </h1>

                                            <div className="image md:hidden md:w-48 w-full mx-auto object-center text-center">
                                                <img className="h-full" src={teamSpirit} alt={"team_spirit"} loading="lazy" />
                                            </div>

                                            <p className="mt-6 leading-8 text-gray-700">
                                                <span className="text-orange-600 font-normal text-xl md:text-2xl">Bigfan</span> is a platform crafted to empower creatives—whether you're a musician, DJ, actor, influencer, content creator, or event organizer—by turning every interaction into a meaningful connection.
                                            </p>

                                            <p className="mt-6 leading-8 text-gray-700">
                                                It fosters a culture where creativity is recognized and celebrated, turning moments of connection into meaningful support for the artistry that inspires them.
                                            </p>

                                            <p className="mt-6 leading-8 text-gray-700">
                                                By turning each moment of artistic expression into an opportunity for support, Bigfan allows audiences to directly show appreciation and encouragement, driving deeper engagement and recognition for every creation.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full px-3 pb-4 text-gray-600">
                            <div className="mx-auto py-3 container md:text-xl text-base md:max-w-4xl lg:max-w-4xl xl:max-w-5xl flex flex-col">
                                <h2 className="font-heading mb-3 lg:text-3xl mt-2 text-2xl font-bold tracking-tight text-orange-600 sm:text-3xl">
                                    How it Works
                                </h2>

                                <p className="mb-6 leading-8 text-gray-700">
                                    Show your appreciation in just 5 simple steps.
                                </p>

                                <div className="mx-auto">
                                    <div className="flex">
                                        <div className="mr-4 flex flex-col items-center">
                                            <div>
                                                <div className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-orange-700"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                    className="h-6 w-6 text-orange-700">
                                                    <path d="M12 5l0 14"></path>
                                                    <path d="M18 13l-6 6"></path>
                                                    <path d="M6 13l6 6"></path>
                                                </svg></div>
                                            </div>
                                            <div className="h-full w-px bg-gray-300"></div>
                                        </div>
                                        <div className="pt-1 pb-8">
                                            <p className="mb-2 text-xl font-bold text-gray-900">
                                                Step 1
                                            </p>

                                            <p className="text-gray-600">
                                                Create an Account
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <div className="mr-4 flex flex-col items-center">
                                            <div>
                                                <div className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-orange-700"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                    className="h-6 w-6 text-orange-700">
                                                    <path d="M12 5l0 14"></path>
                                                    <path d="M18 13l-6 6"></path>
                                                    <path d="M6 13l6 6"></path>
                                                </svg></div>
                                            </div>
                                            <div className="h-full w-px bg-gray-300"></div>
                                        </div>
                                        <div className="pt-1 pb-8">
                                            <p className="mb-2 text-xl font-bold text-gray-900">
                                                Step 2
                                            </p>

                                            <p className="text-gray-600">
                                                Review & Submit
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <div className="mr-4 flex flex-col items-center">
                                            <div>
                                                <div className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-orange-700"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                    className="h-6 w-6 text-orange-700">
                                                    <path d="M12 5l0 14"></path>
                                                    <path d="M18 13l-6 6"></path>
                                                    <path d="M6 13l6 6"></path>
                                                </svg></div>
                                            </div>
                                            <div className="h-full w-px bg-gray-300"></div>
                                        </div>
                                        <div className="pt-1 pb-8">
                                            <p className="mb-2 text-xl font-bold text-gray-900">
                                                Step 3
                                            </p>

                                            <p className="text-gray-600">
                                                Add Account Details
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <div className="mr-4 flex flex-col items-center">
                                            <div>
                                                <div className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-orange-700"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                    className="h-6 w-6 text-orange-700">
                                                    <path d="M12 5l0 14"></path>
                                                    <path d="M18 13l-6 6"></path>
                                                    <path d="M6 13l6 6"></path>
                                                </svg></div>
                                            </div>
                                            <div className="h-full w-px bg-gray-300"></div>
                                        </div>
                                        <div className="pt-1 pb-8">
                                            <p className="mb-2 text-xl font-bold text-gray-900">
                                                Step 4
                                            </p>

                                            <p className="text-gray-600">
                                                Download Your QR Code
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <div className="mr-4 flex flex-col items-center">
                                            <div>
                                                <div className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-orange-700"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                    className="h-6 w-6 text-orange-700">
                                                    <path d="M12 5l0 14"></path>
                                                    <path d="M18 13l-6 6"></path>
                                                    <path d="M6 13l6 6"></path>
                                                </svg></div>
                                            </div>
                                            <div className="h-full w-px bg-gray-300"></div>
                                        </div>
                                        <div className="pt-1 pb-8">
                                            <p className="mb-2 text-xl font-bold text-gray-900">
                                                Step 5
                                            </p>
                                            <p className="text-gray-600">
                                                Withdraw Your Money
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <div className="mr-4 flex flex-col items-center">
                                            <div>
                                                <div
                                                    className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-orange-700 bg-orange-700">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                        className="h-6 w-6 text-white">
                                                        <path d="M5 12l5 5l10 -10"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pt-1 ">
                                            <p className="mb-2 text-xl font-bold text-gray-900">
                                                And Done!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <GetStarted />

                        <Footer />

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}