import { Link } from "react-router-dom"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { debounce } from 'lodash';

import colorfulLogo from "../../assets/images/1akbR3BuvCSqw5uGy.svg"
import { Loading } from "../../components/modules/Loading";
import { GUEST } from "../../api/API_Registry";
import HttpServices from "../../services/HttpServices";

export const LandingHeader = () => {
    const searchInputRef = useRef(null);
    const [scrolled, setScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [query, setQuery] = useState('')
    const [results, setResults] = useState([])
    const [status, setStatus] = useState('pending')
    const [typingTimeout, setTypingTimeout] = useState(null)

    const data = ["Apple", "Banana", "Cherry", "Date", "Elderberry", "Fig", "Grape"];

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const searchInput = searchInputRef.current;
        const searchContainer = searchInput.parentElement;
        const searchIcon = searchContainer.querySelector('svg');

        const handleFocus = () => {
            searchContainer.classList.add('w-64');
            searchContainer.classList.remove('w-48');
            searchInput.classList.remove('border-gray-300');
            searchInput.classList.add('border-orange-500');
            searchIcon.classList.add('text-orange-500');
            searchIcon.classList.remove('text-gray-400');
        };

        const handleBlur = () => {
            searchContainer.classList.add('w-48');
            searchContainer.classList.remove('w-64');
            searchInput.classList.add('border-gray-300');
            searchInput.classList.remove('border-orange-500');
            searchIcon.classList.add('text-gray-400');
            searchIcon.classList.remove('text-orange-500');
        };

        searchInput.addEventListener('focus', handleFocus);
        searchInput.addEventListener('blur', handleBlur);

        // Clean up event listeners on component unmount
        return () => {
            searchInput.removeEventListener('focus', handleFocus);
            searchInput.removeEventListener('blur', handleBlur);
        };
    }, []);

    const handleKeyUp = (e: any) => {
        const searchTerm = e.target.value;
        setQuery(searchTerm);

        if (typingTimeout) clearTimeout(typingTimeout);

        const newTimeout = setTimeout(() => {
            handleSearch(searchTerm);
        }, 700);

        setTypingTimeout(newTimeout);
    };

    const handleSearch = async (searchTerm: any) => {
        if (searchTerm) {
            try {
                setStatus('pending')
                const searchURL = GUEST.FIND_CREATOR + '?query=' + searchTerm

                const searchResponse: any = await HttpServices.httpGet(searchURL)

                if (searchResponse.status === 200) {
                    setStatus('fulfilled')
                    setResults(searchResponse.data)
                }
            } catch (error) {
                setStatus('fulfilled')
            }
        } else {
            setResults([]); // Clear results if input is empty
        }
    };

    return (
        <React.Fragment>
            <nav className={`fixed z-10 top-0 left-0 w-full transition duration-300 ${scrolled ? 'backdrop-blur-md bg-white/70' : 'bg-transparent'}`} >
                <div className="w-full mx-auto md:py-2 py-1.5">
                    <div className="max-w-full mx-auto md:px-4 px-2">
                        <div className="relative flex items-center justify-between md:h-24 h-16">
                            <div className="absolute inset-y-0 w-full left-0 sm:hidden">
                                <div className="flex w-full items-center">
                                    <div className="flex-grow">
                                        <Link to="/" className="mb-0 font-medium px-0 rounded-md" aria-current="page">
                                            <img className="h-12" src={colorfulLogo} loading="lazy" alt="colorful_logo" />
                                        </Link>
                                    </div>

                                    <button
                                        type="button"
                                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                                        className="inline-flex items-center justify-center p-2 rounded-md bg-orange-600 text-white focus:bg-orange-600 hover:text-white hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        aria-controls="mobile-menu"
                                        aria-expanded={isMenuOpen ? 'true' : 'false'}
                                    >
                                        <span className="sr-only">Open main menu</span>
                                        {isMenuOpen ? (
                                            <svg
                                                className="block h-6 w-6"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                className="block h-6 w-6"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M4 6h16M4 12h16M4 18h16"
                                                />
                                            </svg>
                                        )}
                                    </button>
                                </div>

                                <div className={`${scrolled ? 'text-gray-600 hidden' : 'text-white'}`}>
                                    <div className={`absolute w-full mt-4 transition-all px-3 bg-transparent font-medium ${scrolled ? 'text-gray-600 hidden' : 'text-white'}`}>
                                        <div className="w-full relative">
                                            <input type="text" placeholder="Search..." onKeyUp={handleKeyUp} className="w-full py-2 pl-12 pr-4 text-orange-600 border rounded-full outline-none transition-all border-gray-300 focus:placeholder:text-orange-500 font-medium" />

                                            <div className="absolute inset-y-0 left-0 flex items-center px-6">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-gray-400 transition-all" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                                </svg>
                                            </div>

                                            {
                                                query && (
                                                    <div className="w-full absolute text-gray-600 top-10 -mr-4 mt-2 py-2 bg-white border rounded-lg shadow-lg">
                                                        {
                                                            status === 'pending' ? (
                                                                <div className="flex flex-col align-middle py-4">
                                                                    <span className="fa-duotone text-orange-500 fa-spinner fa-xl m-auto block fa-spin"></span>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    {
                                                                        results.length > 0 ? (
                                                                            <ul id="list-item" className="w-full text-gray-600">
                                                                                {
                                                                                    results.map((result) => (
                                                                                        <li key={result.entity_uuid} className="py-2 w-full px-4 hover:bg-gray-200 hover:cursor-pointer">
                                                                                            <Link to={`/c/${result.link}`} className="py-2 w-full px-4">
                                                                                                {result.entity_name}
                                                                                            </Link>
                                                                                        </li>
                                                                                    ))
                                                                                }
                                                                            </ul>
                                                                        ) : (
                                                                            <ul id="list-item" className="w-full text-gray-600">
                                                                                <li className="py-2 px-4 hover:bg-gray-100">
                                                                                    Creator not found
                                                                                </li>
                                                                            </ul>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="hidden sm:block">
                                    <div className="flex space-x-4">
                                        <Link to="/" className="mb-0 font-medium px-0 py-2 rounded-md" aria-current="page">
                                            <img className="h-24  py-3 mx-auto" src={colorfulLogo} loading="lazy" alt="colorful_logo" />
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className={`hidden sm:flex absolute inset-y-0 right-0 ${scrolled ? 'text-gray-600 font-normal' : 'text-white font-normal'} items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 gap-x-6`}>
                                <Link to="/how-it-works" className="hover:text-orange-600">
                                    How it Works
                                </Link>

                                <Link to="/faqs" className="hover:text-orange-600">
                                    FAQs
                                </Link>

                                <Link to="/about-us" className="hover:text-orange-600">
                                    About Us
                                </Link>

                                <Link to="/contact-us" className="hover:text-orange-600">
                                    Contact Us
                                </Link>

                                <div className="relative w-48 transition-all">
                                    <input type="text" placeholder="Search..." onKeyUp={handleKeyUp} ref={searchInputRef} className="w-full py-2 pl-10 pr-4 text-orange-600 bg-white border rounded-full outline-none transition-all border-gray-300 focus:placeholder:text-orange-500 font-medium" />
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-gray-400 transition-all" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                        </svg>
                                    </div>

                                    {
                                        query && (
                                            <div className="w-full absolute text-gray-600 top-10 mt-2 py-2 bg-white border rounded-lg shadow-lg">
                                                {
                                                    status === 'pending' ? (
                                                        <div className="flex flex-col align-middle py-4">
                                                            <span className="fa-duotone text-orange-500 fa-spinner fa-xl m-auto block fa-spin"></span>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {
                                                                results.length > 0 ? (
                                                                    <ul id="list-item" className="w-full text-gray-600">
                                                                        {
                                                                            results.map((result) => (
                                                                                <li key={result.entity_uuid} className="py-2 w-full px-4 hover:bg-gray-200 hover:cursor-pointer">
                                                                                    <Link to={`/c/${result.link}`} className="py-2 w-full px-4">
                                                                                        {result.entity_name}
                                                                                    </Link>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                ) : (
                                                                    <>
                                                                        <li className="py-2 px-4 hover:bg-gray-100">
                                                                            Creator not found
                                                                        </li>
                                                                    </>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                            </div>
                                        )
                                    }


                                </div>

                                <Link to="/auth/sign-in" className="hover:text-orange-700 py-1.5 rounded-md">
                                    Sign In
                                </Link>

                                <Link to="/auth/sign-up" className={`border ${scrolled ? 'bg-orange-600 text-white border-orange-600 hover:bg-orange-700 hover:border-orange-700' : 'hover:scale-105'} py-1.5 px-4 rounded-md`}>
                                    Get Started
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    isMenuOpen && (
                        <div className="sm:hidden absolute top-[76px] left-0 w-full bg-white shadow-lg px-3 pb-2">
                            <div className="flex flex-col items-start space-y-4 py-4 px-6 w-full">
                                <Link to="/how-it-works" className="text-gray-600 hover:text-gray-700 hover:underline focus:underline" onClick={() => setIsMenuOpen(false)}>
                                    How it Works
                                </Link>

                                <Link to="/faqs" className="text-gray-600 hover:text-gray-700 hover:underline focus:underline" onClick={() => setIsMenuOpen(false)}>
                                    FAQs
                                </Link>

                                <Link to="/about-us" className="text-gray-600 hover:text-gray-700 hover:underline focus:underline" onClick={() => setIsMenuOpen(false)}>
                                    About Us
                                </Link>

                                <Link to="/contact-us" className="text-gray-600 hover:text-gray-700 hover:underline focus:underline" onClick={() => setIsMenuOpen(false)}>
                                    Contact Us
                                </Link>

                                <div className="border-t pt-3 w-full">
                                    <Link to="/auth/sign-in" className="text-gray-600 hover:text-gray-700 hover:underline focus:underline flex gap-x-3 items-center align-middle" onClick={() => setIsMenuOpen(false)}>
                                        Sign In
                                    </Link>

                                    <Link to="/auth/sign-up" className="text-orange-600 hover:text-gray-700 hover:underline focus:underline" onClick={() => setIsMenuOpen(false)}>
                                        Get Started
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )
                }
            </nav>
        </React.Fragment>
    )
}